module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eurotransport | TruckJobs","short_name":"TruckJobs","start_url":"/","background_color":"#ffffff","theme_color":"#022c49","display":"minimal-ui","icon":"src/images/etrfavicon.ico"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NTB4D98","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
